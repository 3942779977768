@import "../../styles/variables.scss";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 40px;
}
.row {
  @include contentCenter;
  justify-content: space-between;
  color: white;
  margin-bottom: 5px;
}
.progressBar {
  width: 100%;
  height: 17px;
  background: #ffffff;
  border-radius: 20px;
}
.filledProgressBar {
  border-radius: 20px;
  background-color: $primaryColor;
  height: 17px;
}
.textStyle {
  @include regularText;
  color: black;
  font-size: $mediumFont;
}
.backArrowContainer {
  @include contentCenter;
  padding: 0px 20px 0px 0px;
  border-right: 1px solid white;
  // margin-right: 20px;
}

.headerBrandLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}
.backArrow {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0;
}

.outletLogo {
  display: flex;
  align-items: flex-start;
  width: 25%;
}
